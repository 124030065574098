import "core-js/modules/es.array.filter";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.replace"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import pathToRegexp from 'path-to-regexp'

export default {
  data: function data() {
    return {
      levelList: null };

  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    } },

  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(
      function (item) {return item.meta && item.meta.title;});


      this.levelList = matched.filter(
      function (item) {return item.meta && item.meta.title && item.meta.breadcrumb !== false;});

    },

    // pathCompile(path) {
    //   // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    //   const { params } = this.$route
    //   var toPath = pathToRegexp.compile(path)
    //   return toPath(params)
    // },
    handleLink: function handleLink(item) {var
      redirect = item.redirect,path = item.path;
      if (redirect) {
        this.$router.replace(redirect);
        return;
      }
      this.$router.replace(path);
      // this.$router.push(this.pathCompile(path))
    } } };